import { StandaloneMoreEpisodes } from 'base/components/MoreEpisodes';

export const HantStandaloneMoreEpisodes: typeof StandaloneMoreEpisodes = (props) => (
  <StandaloneMoreEpisodes
    options={{
      $list: {
        className: 'flex flex-col gap-4 md:gap-6',
      },
    }}
    {...props}
  />
);

export default HantStandaloneMoreEpisodes;
