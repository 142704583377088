import deepmerge from 'deepmerge';

export type MergeDeepOptions = deepmerge.Options;

/** @deprecated Use `merge` from `lib/utils/merge` */
export const mergeDeep = <Base extends {}, Overlay extends {} = Base>(
base?: Base,
  fragment?: Overlay,
  options?: MergeDeepOptions
) => {
  return deepmerge(base ?? {}, fragment ?? {}, options) as Base & Overlay;
};

export default mergeDeep;
