import { tw } from '@/utils/tw';
import BaseBylineTheme from 'base/components/Byline/theme';

const Byline = tw.theme({
  extend: BaseBylineTheme,
  slots: {
    author: `text-headline-sm`,
    text: `mr-2 text-gray-600 text-input-md`,
  },
});

export default Byline;
