import {
  StandaloneArticleTeaserBodytext,
  StandaloneArticleTeaserBodytextProps,
} from 'base/components/ArticleTeaser/Bodytext';
import deepmerge from 'deepmerge';

export const HantStandaloneArticleTeaserBodytext: typeof StandaloneArticleTeaserBodytext = ({ options, ...props }) => (
  <StandaloneArticleTeaserBodytext
    options={deepmerge<StandaloneArticleTeaserBodytextProps['options']>(options, {
      variant: 'third',
    })}
    {...props}
  />
);
