/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_default": "group/front data-[opaque]:wings-gray-100 data-[opaque]:bg-gray-100",
  "size_default": "min-h-screen my-3 gap-y-3 px-3",
  "variant_default": "flex flex-col [&:not(:has(.fullscreen-parent))]:container-inline empty:hidden group/front relative data-[opaque]:wings"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Front");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;