/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "variant_default": "flex flex-col items-start gap-y-1 group",
  "label_variant_default": "text-input-md",
  "label_variant_floating": "text-input-md absolute top-3 left-2.5 duration-300 transform scale-75 z-10 origin-top-left -translate-y-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-1",
  "label_status_default": "text-black group-[&:hover:not(:focus-within)]:text-black group-focus-within:text-secondary-700",
  "label_status_error": "text-red-700",
  "field_status_default": "placeholder:text-gray-700 border-gray-700 hover:border-black bg-white focus:border-secondary-700 focus:border-2 focus:outline focus:outline-4 focus:outline-offset-0 focus:outline-secondary-200",
  "field_status_error": "placeholder:text-gray-700 focus:placeholder:text-gray-700 focus:outline focus:outline-4 focus:outline-offset-0 border-red-700 border-2 bg-white focus:outline-red-200",
  "field_variant_default": "block w-full resize-none box-border p-2.5 leading-6.5 border border-solid rounded appearance-none",
  "field_variant_floating": "block w-full resize-none box-border p-2.5 pt-7 leading-6.5 border border-solid rounded appearance-none focus:ring-0 peer placeholder:!text-transparent",
  "field_size_default": "h-35 w-55 text-input-md",
  "field_group_variant_default": "flex flex-col-reverse gap-y-1",
  "field_group_variant_floating": "relative",
  "caption_variant_default": "text-input-sm",
  "caption_status_error": "text-red-700",
  "caption_group_size_default": "gap-2.5",
  "caption_group_variant_default": "flex items-center",
  "caption_group_status_error": "text-red-700"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "TextArea");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;