import { ArticleTeaserDefault } from '@/components/ArticleTeaser/Default';
import { Link } from '@/components/Link';
import { LoopAround } from '@/components/LoopAround';
import { OptionalLinkProps } from '@/components/OptionalLink';
import { TitlePosition } from '@/hant/components/ArticleTeaser/Default';
import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';
import { slugify } from '@/utils/index';
import { stripMultiLineHtml } from '@/utils/stripHtml';

import { findFirstOfType } from 'lib/labrador/content-operations/findBy';
import { useEffect, useRef, useState } from 'react';

export const HantLabradorArticleTeaserDefault: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [titlePosition, setTitlePosition] = useState<TitlePosition>('standard');
  const [{ device }] = useAppState();
  const ref = useRef<HTMLDivElement>(null);

  // TODO: remove title position after unifying Hant article teaser in Labrador
  useEffect(() => {
    if (ref.current) {
      const titleAttribute = ref.current.parentElement?.dataset?.titlePosition as TitlePosition;

      if (titleAttribute) {
        setTitlePosition(titleAttribute);
      }
    }
  }, []);

  const { prominentTag, badge, badgeData, title, publishedUrl, kicker, category } = data ?? {};
  const { name: badgeName } = badgeData ?? {};
  const { nativeAdLabel, teaserIndex } = meta ?? {};

  const image = findFirstOfType(descendants, 'labradorImage');
  const imageUrl = image?.data?.imageUrl?.[device];
  const imageSearchParams = new URLSearchParams(imageUrl || '');
  const imageWidth = imageSearchParams.get('width');
  const imageHeight = imageSearchParams.get('height');
  const imageAspectRatio = imageWidth && imageHeight ? `${imageWidth}/${imageHeight}` : '2/1';

  const articleId = publishedUrl?.split('/').at(-1);

  const isPriority = (teaserIndex ?? 0) < 4;
  const position = titlePosition === 'standard' ? '' : titlePosition;

  const badgeHeader = badgeName && (
    <LoopAround fragment={<span>{badgeName}</span>} options={{ variant: 'secondary' }} />
  );

  const nativeAdLabelCaption: OptionalLinkProps = nativeAdLabel && {
    href: `etikett/${slugify(nativeAdLabel, { replacement: ' ' })}`,
  };

  const nativeAdLabelHeader = nativeAdLabel && (
    <Link
      {...nativeAdLabelCaption}
      content={nativeAdLabel}
      options={{
        className: 'text-headline-xs flex h-full grow items-center justify-center bg-black normal-case text-white',
      }}
    />
  );

  const header = nativeAdLabelHeader || badgeHeader || null;
  const headline = stripMultiLineHtml(title);

  const categoryCaption: OptionalLinkProps = {
    href: slugify(category) === 'noje' ? '/' : slugify(category),
  };

  const prominentTagCaption: OptionalLinkProps = prominentTag && {
    href: `etikett/${prominentTag}`,
    'data-cts-creative': `prominent-tag-teaser:${prominentTag}`,
    'data-cts-path': `/etikett/${prominentTag}`,
    'data-cts-label': `prominent-tag-teaser:${prominentTag}`,
    'data-cts-name': `/etikett/${prominentTag}`,
    'data-cts-id': articleId,
    options: {
      className: 'cts-impression-item',
    },
  };

  const ctsLabel = [badge ? `article-teaser-ticker:${badge}` : `standard-article-teaser`, position].join(' ');

  return (
    <div ref={ref}>
      <ArticleTeaserDefault
        data-index={teaserIndex}
        header={header}
        headline={headline}
        caption={nativeAdLabel || prominentTag || category}
        kicker={kicker}
        links={{
          article: {
            href: publishedUrl,
            'data-cts-path': publishedUrl,
            'data-cts-name': headline,
            'data-cts-label': ctsLabel,
            'data-cts-creative': ctsLabel,
            'data-cts-id': articleId,
          },
          caption: nativeAdLabelCaption || prominentTagCaption || categoryCaption,
        }}
        image={{
          src: imageUrl,
          options: {
            priority: isPriority,
            style: {
              aspectRatio: imageAspectRatio,
            },
          },
        }}
      />
    </div>
  );
};
