/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "label_colors_default": "",
  "label_size_default": "text-input-md ml-2.5",
  "label_variant_default": "",
  "input_colors_default": "checked:bg-black shadow-black hover:shadow-black active:shadow-black focus:shadow-secondary-700 outline-secondary-200 before:bg-white before:active:bg-gray-100",
  "input_size_default": "h-5 w-5 p-1.25 shadow-inset-1 hover:shadow-inset-2 focus:shadow-inset-2 outline outline-0 hover:outline-4 active:outline-0 focus:outline-4 before:w-full before:h-full",
  "input_variant_default": "appearance-none bg-clip-content rounded-full transition-all relative before:block before:absolute before:top-0 before:left-0 before:-z-1 before:rounded-full before:transition-colors",
  "colors_default": "",
  "size_default": "my-4",
  "variant_default": "flex items-center"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "RadioButton");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;