import { tw } from '@/utils/tw';
import BaseBreakingNewsTheme from 'base/components/BreakingNews/theme';

const Icon = tw.theme({
  extend: BaseBreakingNewsTheme.Icon,
});

const BreakingNews = tw.theme({
  extend: BaseBreakingNewsTheme,
  variants: {
    variant: {
      primary: {
        base: `bg-white`,
        blip: `bg-red-700 before:bg-red-700 after:bg-red-700`,
        label: `text-red-700`,
      },
    },
  },
});

export default Object.assign(BreakingNews, { Icon });
