import { tw } from '@/utils/tw';
import BaseLoopAroundTheme from 'base/components/LoopAround/theme';

const LoopAround = tw.theme({
  extend: BaseLoopAroundTheme,
  variants: {
    variant: {
      primary: {
        base: `bg-transparent py-1.5 uppercase italic text-black`,
        reel: `text-headline-sm [&_span]:before:mx-1 [&_span]:before:content-["•"]`,
      },
      secondary: {
        base: `flex flex-row flex-nowrap overflow-hidden whitespace-nowrap bg-transparent uppercase italic text-white [text-shadow:1px_2px_0_black]`,
        reel: `animate-loop uppercase italic text-headline-2xs container-col-sm-8:text-headline-xs md:text-headline-sm [&_span]:before:mx-1 [&_span]:before:content-["•"]`,
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export default LoopAround;
