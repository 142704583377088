/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "headline_colors_default": "",
  "headline_size_default": "text-headline-sm sm:text-headline-md mb-3 uppercase",
  "headline_variant_default": "",
  "colors_default": "",
  "size_default": "my-4",
  "variant_default": "cts-impression-item not-prose hidden"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "VideoReels");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;