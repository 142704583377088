import { Icon } from '@/components/Icon';
import { JwVideoTeaser, JwVideoTeaserProps } from '@/components/JwVideo/Teaser';
import { LoopAround } from '@/components/LoopAround';
import { TitlePosition } from '@/hant/components/ArticleTeaser/Default';
import { useAppState } from '@/hooks/useAppState';
import { componentTheme } from '@/styles/JwVideoTeaser';
import { LabradorComponent } from '@/types/component';
import cn from '@/utils/cn';

import { useEffect, useRef, useState } from 'react';

export const HantLabradorJwVideoTeaser: LabradorComponent = ({ type, meta, data }) => {
  const {
    videoID,
    thumbnail,
    isFrontPage,
    teaserLink,
    title,
    stillImage,
    badgeData,
    prominentTag,
    displayProminentTagOnTeaser,
  } = data;

  const { name: badgeName } = badgeData || {};

  const [titlePosition, setTitlePosition] = useState<TitlePosition>('standard');

  const elemRef = useRef<HTMLDivElement>(null);

  const [{ affiliate }] = useAppState();

  const isNoje = affiliate === 'noje';

  const headline = badgeName ? (
    <LoopAround fragment={<span>{badgeName}</span>} />
  ) : displayProminentTagOnTeaser ? (
    <>
      {prominentTag}
      <Icon options={{ className: cn('rotate-180', '!h-4', '!w-4') }} name="sliderArrow" />
    </>
  ) : null;

  useEffect(() => {
    if (isFrontPage && elemRef.current) {
      const titleAttribute = elemRef.current.parentElement?.dataset?.titlePosition as TitlePosition;
      if (titleAttribute) {
        setTitlePosition(titleAttribute);
      }
    }
  }, [isFrontPage]);

  const badgeText = badgeName ?? (displayProminentTagOnTeaser && prominentTag);

  const componentProps: JwVideoTeaserProps = {
    mediaId: videoID,
    teaserLink,
    thumbnail,
    title,
    isStillImage: stillImage,
    tag: prominentTag,
    ...(isFrontPage
      ? {
          headline,
          ctsLabel: `video-teaser ${titlePosition}${badgeText && ' ticker:' + badgeText}`,
          options: {
            theme: componentTheme('front_page'),
            variant: 'vertical',
            $content: {
              colors: 'default',
              $tag: {
                theme: componentTheme('tag'),
              },
            },
            $link: {
              'data-ticker-text': badgeText,
            },
            $headline: {
              hideLine: true,
            },
          },
        }
      : {
          headline: 'Videos från Hänt',
          options: {
            variant: 'horizontal',
            $content: {
              colors: 'primary',
              className: isNoje ? 'border-primary-700' : 'border-secondary-700',
              $tag: {
                theme: componentTheme('tag'),
              },
            },
            $headline: {
              hideLine: true,
            },
          },
        }),
  };

  return (
    <div ref={elemRef}>
      <JwVideoTeaser {...componentProps} />
    </div>
  );
};
