import { mergeProps } from '@/utils/merge';
import { StandaloneShowsSlider } from 'base/components/ShowsSlider';

export const HantStandaloneShowsSlider: typeof StandaloneShowsSlider = (props) => (
  <StandaloneShowsSlider
    {...mergeProps(
      {
        options: {
          $swiper: {
            $content: {
              size: 'none',
            },
            spaceBetween: 12,
          },
        },
      },
      props,
    )}
  />
);
